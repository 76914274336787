// document.addEventListener('DOMContentLoaded', function () {
//     // Handle select button clicks
//     const wrappers = document.querySelectorAll(".wrapper");

//     wrappers.forEach(wrapper => {
//         const selectBtn = wrapper.querySelector(".select-btn");

//         selectBtn.addEventListener("click", () => {
//             wrapper.classList.toggle("active");
//         });
//     });

//     // Handle accordion header clicks
//     const accordionContainers = document.querySelectorAll(".accordion-container");

//     accordionContainers.forEach(container => {
//         const accordionHeader = container.querySelector(".accordion-header");
//         const accordionBody = container.querySelector(".accordion-body");

//         // Toggle accordion body visibility
//         accordionHeader.addEventListener('click', () => {
//             const isActive = accordionHeader.classList.toggle('active');
//             accordionBody.style.display = isActive ? 'block' : 'none';
//         });

//         // Handle accordion header table clicks within each accordion
//         const accordionArrows = container.querySelectorAll(".accordion-arrow");

//         accordionArrows.forEach(arrow => {
//             arrow.addEventListener('click', event => {
//                 event.stopPropagation(); // Prevent the event from bubbling up to the accordion header

//                 // Toggle the active class on the header table
//                 const headerTable = arrow.closest('.accordion-header-table');
//                 const accordionTableBody = headerTable.nextElementSibling;

//                 if (accordionTableBody && accordionTableBody.classList.contains('accordion-body-table')) {
//                     const isVisible = accordionTableBody.style.display === 'table-row' || accordionTableBody.style.display === '';
//                     accordionTableBody.style.display = isVisible ? 'none' : 'table-row';
//                 }
//                 arrow.classList.toggle('rotated', !isVisible);
//             });
//         });
//     });
// });




document.addEventListener('DOMContentLoaded', function () {
    // Parent element for event delegation
    const reservationGroupTable = document.getElementById('reservation-group-table');

    if (reservationGroupTable) {
      // Handle select button clicks
      reservationGroupTable.addEventListener('click', (event) => {
        const clickedElement = event.target;
        const wrapper = clickedElement.closest('.wrapper');
    
        if (wrapper && wrapper.querySelector('.select-btn') === clickedElement) {
          wrapper.classList.toggle("active");
        }
      });
    
      // Handle accordion header clicks
      reservationGroupTable.addEventListener('click', (event) => {
        const clickedElement = event.target;
        const accordionHeader = clickedElement.closest('.accordion-header');
    
        if (accordionHeader) {
          const accordionBody = accordionHeader.nextElementSibling;
          if (accordionBody && accordionBody.classList.contains('accordion-body')) {
            const isActive = accordionHeader.classList.toggle('active');
            accordionBody.style.display = isActive ? 'block' : 'none';
          }
        }
      });
    
      // Handle accordion header table clicks within each accordion
      reservationGroupTable.addEventListener('click', (event) => {
        const clickedElement = event.target;
        const accordionArrow = clickedElement.closest('.accordion-arrow');
    
        if (accordionArrow) {
          event.stopPropagation(); // Prevent bubbling
    
          const headerTable = accordionArrow.closest('.accordion-header-table');

          if (headerTable) {
              const accordionTableBody = headerTable.nextElementSibling;
    
              if (accordionTableBody && accordionTableBody.classList.contains('accordion-body-table')) {
              const isVisible = accordionTableBody.style.display === 'table-row' || accordionTableBody.style.display === '';
              accordionTableBody.style.display = isVisible ? 'none' : 'table-row';
              accordionArrow.classList.toggle('rotated', !isVisible);
              }
          }        
        }
      });
    }
  });